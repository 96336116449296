@import './mixins';
body {
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,span{
        font-weight: 600;
        opacity: 1;
    }

    .small-text{
        font-size: 12px;
        cursor: pointer;
        text-decoration: none;
    }

    .custom-button{
        border-radius: 5px;
        border: transparent;
        width: max-content;
        padding: 5px 10px;
        font-size: 14px;
        font-weight: 600;
        @include media-breakpoint-sm{
            width: 100%;
        }
    }

    #contactActionMenu{
        border: none;
        display: flex;
        align-items: center;
        i{
            font-size: 20px;
        }
        &::after{
            display: none;
        }
    }

    .table{
        border: 1px solid #E7E7E7;
        tbody{
            tr{
                td{
                    border-bottom-width: 0;
                }
            }
        }
    }

    //header CSS
    app-header {
        min-height: 70px;
        height: 100%;
        .main-header{
            .logo-section{
                width: 232px;
            }
            .menu-toggle-button{
                // Toggle button styling
                .toggle-button {
                    border: none;
                    padding: 7px 10px;
                    cursor: pointer;
                    z-index: 1000;
                    border-radius: 10px;
                }
            }
            .user-menu{
                .dropdown{
                    button{
                        &::after{
                            display: none;
                        }
                    }
                }
            }
        }
    }

    // Sidebar styling
    .sidebar {
        width: 244px;
        padding: 0 0 0 20px;
        height: 100vh;
        overflow-y: auto;
        transition: width 0.3s ease;

        &.collapsed {
            width: 75px;
            ul {
                li {
                    padding-right: 10px;
                    .menuList{
                        padding: 7px;
                        a {
                            justify-content: center;
                            word-wrap: none;
                            .icon {
                                i{
                                    margin-right: 0;
                                    font-size: 16px;
                                }
                            }
    
                            .name {
                                display: none; // Hide text when collapsed
                            }
                        }
                    }
                  
                }
            }
        }
        ul {
            list-style-type: none;
            padding: 0;
            li {
                margin: 15px 0;
                display: flex;
                flex-direction: row;
                gap: 10px;
                padding-right: 20px;
                .menuList{
                    padding: 7px 0px 7px 9px;
                    border-radius: 5px;
                    width: 100%;
                    a {
                        word-break: keep-all;
                        text-decoration: none;
                        font-weight: bold;
                        display: flex;
                        align-items: center;
                        text-wrap: nowrap;
                        .icon {
                            i{
                                margin-right: 10px;
                                font-size: 16px;
                            }
                        }
                    }
                }
                
            }
        }
    }

    // Main content adjustment for sidebar toggle
    .main-content-section{
        width: 100%;
        overflow-y: auto;
        height: 91vh;
        min-height: 100%;
        .main-content {
            min-height: auto;
            padding: 21px 25px;
            transition: margin-left 0.3s ease;
            width: 100%;
            &.expanded {
                // margin-left: 30px;
                // margin-top: 21px;
                width: 100%;
            }
        }
    }
    

    //Dashboard Page SCSS
    .box{
        padding: 25px 20px;
        border-radius: 5px;
        border: transparent;
        @include media-breakpoint-xl{
            margin-bottom: 20px;
        }
        .icon{
            padding: 8.5px 8px;
            border-radius: 50%;
            i{
                font-size: 20px;
            }
        }
        .text{
            .count{
                font-size: 24px;
            }
            .nameCount{
                font-size: 13px;
            }
        }
    }

    // API-Page SCSS
    .api-custom-style {
    font-weight: 400;
    cursor: pointer;
    } 
    .custom-font {
    font-size: 14px;
    }
        
    // Whatsapp-Template-Page SCSS
    .nav-underline {
        .nav-link {
            &.active.custom-tab-link {
                font-family: "Inter", sans-serif;
                font-weight: 600;
                font-size: 20px;
                color: #002B3A;
            }
        }
    }

    //Campaigns Page SCSS
    .campaign-img {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        min-height: calc(100vh - 200px);
    }

    //Contact Page
    .contact-page{
        .contact-import-box{
            border: 3px dashed #B5B5B5;
            padding: 21px 15px;
            i{
                color: #002b3a;
                font-size: 70px;
            }
            a{
                cursor: pointer;
            }
        }
    }

    //Login Page SCSS
    .login-box {
        margin: auto;
        padding: 20px;
        border: 1px solid #ccc;
        border-radius: 8px;
        box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
        background-color: #fff;
        width: 400px; 
        min-height: 500px;
    }

    //Header Page SCSS
    @include media-breakpoint-xxl {
        .pagetitle {
          display: none;
        }
        
        .user-menu {
          margin-left: auto; // Keeps user menu at the end
        }
      }

      //Custom class for auth pages
      .main-page {
        color:#7b7b7f; 
        font-weight: 400; 
        cursor: pointer;
      }
}