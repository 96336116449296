
body {
    
    h1,
    h2,
    h3,
    h4,
    h5,
    h6{
        color: $text-color;
    }

    .btn-secondary:disabled,.btn-secondary{
        color: $white-color;
    }

    .table{
        thead{
            tr{
                th{
                    padding: 14px 8px;
                }
            }
        }
        tbody{
            tr{
                td{
                    // padding: 14px 8px;
                }
            }
        }
    }

    .table-striped > tbody > tr:nth-of-type(odd) > *{
        background-color: #f8fff8;
        // height: 54px;
    }

    .small-text{
       color: $text-color;
    }

    .small-text-green{
        color: $secondary;
     }

    .custom-button{
        color: $white-color;
        &:hover{
            color: $white-color;
        }
    }

    .btn{
        &:active{
            color: $white-color !important;
        }
        &:hover{
            color: $white-color !important;
        }
    }

    //Interstial Cancel Button 
    .cancel-btn{
        color: $white-color;
    }

    .primary-icon{
        color: $icon-color;
    }

    //header CSS
    app-header {
        .main-header {
            border-bottom: 1px solid $border-color;
        }
    }

     // Container for sidebar
     .sidebar {
        border-right: 1px solid $border-color;
        // background-color: $border-color;
        ul {
            li{
                &.active{
                    border-right: 5px solid $primary;
                    .menuList{
                        background-color: $primary;
                        color: $white-color;
                        a {
                            color: #212529;
                            h6{
                                color: $white-color;
                            }
                            .icon{
                                color: $white-color;
                            }
                        }
                    }   
                }
            }
            li {
                .menuList{
                    a {
                        color: #212529;
                    }
                }
            }
        }
    }

    // Toggle button for sidebar
    .toggle-button {
        background-color: $bg-color;
        color: $white-color;
        i{
            color: $icon-color;
        }
        &:hover {
            background-color: $primary;
            color: $white-color;
            i{
                color: $white-color;
            }
        }
    }

     //Dashboard Page SCSS
    .box{
        .icon{
           background-color: $white-color;
        }
    }
    .box-1{
        background-color: #5CBA5D1A;
    }
    .box-2{
        background-color: #01877A1A;
    }
    .box-3{
        background-color: #3CD6BC1A;
    }
    .box-4{
        background-color: #005B801A;
    }

    .custom-theme-color {
        color: $primary;
    }
    
      //Whatsapp-Template-Page SCSS
      .nav-underline {
        .nav-link {
            &.active.custom-tab-link {
          border-bottom-color: $primary;
            }
        }
      }
}
