$primary: #048679;
$secondary: #038879;
$text-color: #002B3A;
$white-color: #ffffff;
$black-color: #000000;
$border-color: #f8f9fa;
$icon-color : #045B80;
$bg-color : #EAEAEA;
$border-color : #E7E7E7;
$card-border-color: #D9D9D9;
$danger : #FF0000;
$hover-color: #f8fff8;

/* ------------ Updated the default theme with new colors theme-map-merge starts here ------------  */
$theme-colors: () !default;

$theme-colors: map-merge(
    (
        "primary": $primary,
        "secondary": $secondary,
        "danger": $danger,
        "hoverColor": $hover-color
    ),
    $theme-colors
);


/* Importing Bootstrap SCSS file. */
@import 'bootstrap/scss/bootstrap';
@import '../node_modules/@fortawesome/fontawesome-free/css/all.css';
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap");
@import '../node_modules/ngx-toastr/toastr.css';
@import '../node_modules/@ng-select/ng-select/themes/default.theme.css';
$font-inter: "Inter", sans-serif;
$font-fontawesome: "Font Awesome 6 Free";

* {
    font-family: $font-inter;
    font-weight: 400;
    &:focus-within {
        border-color: unset;
    }

    &:focus-visible {
        border-color: unset;
    }
}

html,
body {
    height: 100vh;
    width: 100%;
    overflow: hidden;
    border-color: $border-color;
}

::-webkit-scrollbar {
    width: 10px;
}

::-webkit-scrollbar-thumb {
    background-color: $secondary;
    border-radius: 10px;
}

::-webkit-scrollbar-track {
    background-color: #f2f2f2;
    border-radius: 10px;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
    margin: 0;
    padding: 0;
}

.card{
    border-color: $card-border-color;
}

input[type="checkbox"] {
    // background-color: $primary !important;
    border-color: $primary;
}


@import '../src/assets/styles/_custom-theme.scss';
@import '../src/assets/styles/_custom-style.scss';