//Media breakpoint
@mixin media-breakpoint-xs() {
  @media (min-width: 570.98px) {
    @content;
  }
}

@mixin media-breakpoint-sm() {
    @media (max-width: 575.98px) {
      @content;
    }
  }
  
  @mixin media-breakpoint-md() {
    @media (max-width: 767.98px) {
      @content;
    }
  }
  
  @mixin media-breakpoint-lg() {
    @media (max-width: 991.98px) {
      @content;
    }
  }
  
  @mixin media-breakpoint-xl() {
    @media (max-width: 1199.98px) {
      @content;
    }
  }
  
  @mixin media-breakpoint-xxl() {
    @media (max-width: 1290px) {
      @content;
    }
  }